<template>
    <v-modal
        :adaptive="true"
        :width="560"
        @before-open="modalOpened"
        height="auto"
        name="add-event-modal"
        scrollable
        @closed="hideModal"
    >
        <modal-content name="add-event-modal">
            <div slot="header">
                <h2 class="font-normal">{{ title }}</h2>
            </div>
            <div class="flex flex__column" slot="body">
                <div class="flex justify-between">
                    <div>
                        Event will be originated at
                        <span
                            v-if="event.type && ! event.editOriginatedAt"
                        >{{ event.originated_at.format('Y-M-D HH:mm') }}</span>
                    </div>
                    <span
                        @click="event.editOriginatedAt = ! event.editOriginatedAt"
                        class="inline-block ml-2 text-blue-900 cursor-pointer"
                        v-text="event.editOriginatedAt ? 'Cancel' : 'Edit'"
                    >Edit</span>
                </div>
                <modal-field
                    :label="'Status'"
                    :required="true"
                    class="flex-wrap w-full mt-12 mb-2"
                    type="number"
                >
                    <multiselect
                        :multiple="false"
                        :options="status_options"
                        placeholder="Please select status"
                        v-model="event.selected_status"
                        label="label"
                        track-by="value"
                        :class="{'is-invalid': submitter && !event.selected_status.value }"
                    >
                        <span
                            slot="noResult"
                        >Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                </modal-field>
                <modal-field
                    label="Transport event description"
                    class="flex-wrap w-full mt-4 mb-2"
                    type="number"
                    required
                >
                    <input :placeholder="''" class="w-full" type="text" v-model="event.description" :class="{'is-invalid': submitter && !event.description }" />
                </modal-field>
                <modal-field :label="'Location'" class="flex-wrap w-full mt-4 mb-2" type="text">
                    <input :placeholder="''" class="w-full" type="text" v-model="event.location" />
                </modal-field>
                <div v-if="event.editOriginatedAt">
                    <modal-field class="mt-4 mb-4" label="Originated date" required>
                        <date-picker :disable-past="false" v-model="event.originated_at"></date-picker>
                    </modal-field>
                    <modal-field class label="Originated time" required>
                        <div class="flex">
                            <select id="hour" name="hour" v-model="event.originated_at_hour" class="mr-2">
                                <option
                                    :key="'h' + hour"
                                    :value="hour"
                                    v-for="hour in hours"
                                >{{ hour }}</option>
                            </select>
                            <select id="minute" name="minute" v-model="event.originated_at_minute">
                                <option
                                    :key="'m' + minute"
                                    :value="minute"
                                    v-for="minute in minutes"
                                >{{ minute }}</option>
                            </select>
                        </div>
                    </modal-field>
                </div>
                <div>
                    <modal-field class="mt-4 mb-4" label="Date" required>
                        <date-picker :disable-past="false" v-model="event.time" @change="updateTime"></date-picker>
                    </modal-field>
                    <modal-field class label="Time" required>
                        <div class="flex">
                            <select id="hour" name="hour" v-model="event.time_hour" class="mr-2" @change="updateTime">
                                <option
                                    :key="'h' + hour"
                                    :value="hour"
                                    v-for="hour in hours"
                                >{{ hour }}</option>
                            </select>
                            <select id="minute" name="minute" v-model="event.time_minute" @change="updateTime">
                                <option
                                    :key="'m' + minute"
                                    :value="minute"
                                    v-for="minute in minutes"
                                >{{ minute }}</option>
                            </select>
                        </div>
                    </modal-field>
                </div>

                <div class="flex">
                    <label class="cursor-pointer mt-4">
                        <input v-model="is_xception" :disabled="isManualExceptionDisabled" type="checkbox" class="form-checkbox mr-2" />
                        
                        Manual exception
                    </label>
                </div>

                <div v-if="is_xception" class="flex">
                    <label class="cursor-pointer mt-4">
                        <input
                            v-model="not_visible_in_client_side"
                            type="checkbox"
                            class="form-checkbox mr-2"
                            :disabled="disableFieldsByStatus"
                        />
                        
                        Not visible for client
                    </label>
                </div>

                <modal-field
                    v-if="is_xception"
                    label="Exception details"
                    :required="true"
                    class="flex-wrap w-full mt-4"
                    type="number"
                >
                    <multiselect
                        v-model="exception_details"
                        :multiple="false"
                        :options="isOngoingInvestigation ? exceptionDetailsListInvestigation : exceptionDetailsList"
                        label="label"
                        track-by="id"
                        :class="{'is-invalid': submitter && is_xception && !exception_details }"
                        :disabled="disableFieldsByStatus"
                    >
                    </multiselect>
                </modal-field>
            </div>
            <div slot="footer">
                <div class="flex justify-end w-full">
                    <div>
                        <button
                            @click="hideModal"
                            class="px-8 mr-4 btn-grey-outline"
                        >Cancel</button>
                        <button
                            :disabled="loading"
                            @click="sendRequest"
                            class="px-10 btn-primary"
                        >Save</button>
                    </div>
                </div>
                <!-- <pre class="overflow-auto text-xs max-h-80">{{ consignment }}</pre> -->
            </div>
        </modal-content>
    </v-modal>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import DatePicker from '~/components/DatePicker'

function newEvent() {
    return {
        type: 'TRANSPORT_EVENT',
        status_options: [],
        selected_status: '',
        description: '',
        location: '',
        originated_at: moment(),
        originated_at_hour: moment().format('HH'),
        originated_at_minute: moment().format('mm'),
        editETA: false,
        time: moment(),
        time_hour: moment().format('HH'),
        time_minute: moment().format('mm'),
        editOriginatedAt: false
    }
}

const zeroPad = (num, places) => String(num).padStart(places, '0');
const getClosestQuarter = (date) => {
    let now = moment(date).format('mm');
    now = parseInt(now);
    if (now > 0 && now < 15) {
        return 15;
    }
    if (now >= 15 && now < 30) {
        return 30;
    }
    if (now >= 30 && now < 45) {
        return 45;
    }
    return zeroPad(0, 2);
};

const getHourForSelect = (date) => {
    let now = moment(date).format('mm');
    let nowHour = moment(date).format('H');
    if (now > 50) {
        nowHour = parseInt(nowHour) + 1;
    }
    return zeroPad(nowHour, 2);
};

export default {
    name: 'AddEventModal',
    props: {
        shipment: {
            type: Object,
            required: true,
        },
        propEvent: {
            type: Object,
        },
        trigger: {
            type: Number,
            default: 0,
        },
        exceptionDetailsList: {
            type: Array,
            default: () => { return [] }
        }
    },
    components: { DatePicker },
    data() {
        return {
            event: {
                selected_status: {
                    value: ''
                },
            },
            status_options: [],
            exceptionDetailsListInvestigation: [],
            consignment: {},
            loading: false,
            submitter: false,
            isChangedTime: false,
            is_xception: false,
            is_xception_initial: false,
            not_visible_in_client_side: false,
            exception_details: null,
            exception_status: '',
        }
    },
    watch: {
        trigger() {
            if (!this.propEvent) {
                this.setEvent();

                return;
            }

            this.setEventEdit(this.propEvent);
        },
        isOngoingInvestigation(value) {
            if (!value) {
                return;
            }

            this.is_xception = true;

            if (!this.exceptionDetailsListInvestigation.find(option => option.id === this.exception_details?.id)) {
                this.exception_details = null;
            }
        },
    },
    methods: {
        updateTime() {
            this.isChangedTime = true;
        },
        hideModal() {
            this.$emit('reset');
            this.$modal.hide('add-event-modal');
        },
        modalOpened() {
            this.submitter = false;
            this.setEvent();
            this.fetchExtraData();
        },
        setEvent() {
            let event = Object.assign({}, newEvent());
            event.time_minute = this.currentQuarter;
            // event.time_hour = this.currentHour;
            event.originated_at_minute = this.currentQuarter;
            this.event = event
            this.exception_details = null;
            this.not_visible_in_client_side = false;
            this.is_xception = false;
            this.is_xception_initial = false;
            this.exception_status = '';
        },
        setEventEdit(value) {
            const xception = this.shipment.xceptions?.find(xc => xc.event_id === value.id);
            let xceptionDetails = null;

            if (xception?.exception_details) {
                xceptionDetails = this.exceptionDetailsList
                    .find(item => item.id.toLowerCase() === xception.exception_details.toLowerCase());
            }

            this.event.id = value?.id;
            this.event.selected_status = value?.status
                ? this.status_options.find(status => status.value === value?.status)
                : null; 
            this.event.description = value?.description || '';
            this.event.location = value?.location || '';

            this.event.originated_at = moment(value?.originated_at);
            this.event.originated_at_hour = getHourForSelect(value?.originated_at);
            this.event.originated_at_minute = getClosestQuarter(value?.originated_at);

            this.event.time = moment(value?.time);
            this.event.time_hour = getHourForSelect(value?.time);
            this.event.time_minute = getClosestQuarter(value?.time);
            this.is_xception = Boolean(xception);
            this.is_xception_initial = Boolean(xception);
            this.not_visible_in_client_side = xception ? !xception.visible_in_client_side : false;
            this.exception_details = xceptionDetails;
            this.exception_status = value?.xceptions[0]?.status;
        },
        async fetchExtraData() {
            try {
                const { data } = await axios.get(
                    this.$apiUrl.consignments.base + `/${this.shipment.uuid}`
                );
                this.consignment = data.data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$snotify.error(error?.response?.data?.message)
                this.$modal.hide('add-event-modal')
            }
        },
        sendRequest() {
            if (this.isEdit) {
                this.editEvent();

                return;
            }

            this.createEvent();
        },
        async editEvent() {
            this.loading = true;

            try {
                await axios.post(`${this.$apiUrl.consignments.events}${this.event.id}`, this.sendValuesEdit);
                this.$snotify.success(`The event was created successfully`);

                setTimeout(() => {
                    this.loading = false;
                    this.$emit('created');
                    this.hideModal();
                }, 1200);
            } catch (error) {
                this.$snotify.error(
                    error.response?.data?.message ?? `Error on create event`
                );
                this.loading = false;
            }
        },
        async createEvent() {
            this.submitter = true;
            this.loading = true;

            if (!this.event.selected_status?.value || !this.event.description) {
                this.$snotify.error('You must fill status and transport event description');
                this.loading = false;

                return;
            }

            if (this.is_xception && !this.exception_details) {
                this.loading = false;

                return;
            }

            try {
                await axios.post(`${this.$apiUrl.consignments.base}/${this.consignment.uuid}/events`, this.sendValues)
                this.$snotify.success(`The event was created successfully`);
                this.consignment.transportdates_etadate = this.sendValues.time;
                setTimeout(() => {
                    this.loading = false;
                    this.$emit('created');
                    this.$modal.hide('add-event-modal');
                }, 1200);
            } catch (error) {
                this.$snotify.error(
                    error.response?.data?.message ?? `Error on create event`
                );
                this.loading = false;
            }
        },
        async initOptions() {
            const { data: { data } } = await axios.get(this.$apiUrl.dictionary)

            this.status_options = data.shipment_status;
            this.exceptionDetailsListInvestigation = data.exception_details_investigation.map(item => {
                return {
                    label: item.label,
                    id: item.value,
                    active: true,
                    single_only: true,
                };
            });
        },
    },
    computed: {
        disableFieldsByStatus() {
            return Boolean(this.exception_status && this.exception_status !== 'APPLIED');
        },
        title() {
            return `${this.isEdit ? 'Edit' : 'Add'} transport event`;
        },
        isEdit() {
            return Boolean(this.propEvent);
        },
        hours() {
            let hours = [];
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            for (let index = 0; index < 24; index++) {
                let hour = index;
                hours.push(zeroPad(hour, 2));
            }
            return hours;
        },
        currentQuarter() {
            return getClosestQuarter();
        },
        currentHour(date) {
            return getHourForSelect(date);
        },
        minutes() {
            return ['00', '15', '30', '45'];
        },
        showTimeFields() {
            switch (this.event?.selected_status?.value) {
                case 'ETA':
                case 'PICKUP':
                case 'DELIVERED':
                case 'CONFIRMED':
                    return true;
                default:
                    return false;
            }
        },
        sendValuesEdit() {
            const result = {
                originated_at: this.event.originated_at,
                status: this.event.selected_status.value,
                type: this.event.type,
                location: this.event.location,
                description: this.event.description,
                is_xception: Boolean(this.is_xception),
            };

            if (this.is_xception) {
                result.visible_in_client_side = Number(!this.not_visible_in_client_side);
                result.exception_details = this.exception_details?.id || null;
            }

            const originated_at = moment(this.event.originated_at)
                .set({ hour: this.event.originated_at_hour, minute: this.event.originated_at_minute, second: 0 })
                .format('YYYY-MM-DD HH:mm:ss')

            result.originated_at = originated_at;

            const time = this.isChangedTime 
                ? moment(this.event.time)
                    .set({ hour: this.event.time_hour, minute: this.event.time_minute, second: 0 })
                    .format('YYYY-MM-DD HH:mm:ss')
                : moment().format('YYYY-MM-DD HH:mm:ss');

            result.time = time;

            return result;
        },
        sendValues() {
            const result = {
                status: this.event.selected_status?.value || null,
                description: this.event.description || '',
                location: this.event.location || '',
                type: this.event.type || '',
                is_xception: Boolean(this.is_xception),
            }

            if (this.is_xception) {
                result.visible_in_client_side = Number(!this.not_visible_in_client_side);
                result.exception_details = this.exception_details?.id || null;
            }

            if (this.event.editOriginatedAt) {
                const originated_at = moment(this.event.originated_at)
                    .set({ hour: this.event.originated_at_hour, minute: this.event.originated_at_minute, second: 0 })
                    .format('YYYY-MM-DD HH:mm:ss')

                result.originated_at = originated_at;
            } else {
                result.originated_at = moment().format('YYYY-MM-DD HH:mm:ss');
            }

            const time = this.isChangedTime 
                ? moment(this.event.time)
                    .set({ hour: this.event.time_hour, minute: this.event.time_minute, second: 0 })
                    .format('YYYY-MM-DD HH:mm:ss')
                : moment().format('YYYY-MM-DD HH:mm:ss');

            result.time = time;

            return result;
        },
        isOngoingInvestigation() {
            return this.event?.selected_status?.value === 'ONGOING_INVESTIGATION';
        },
        isManualExceptionDisabled() {
            return this.is_xception_initial === true || this.isOngoingInvestigation;
        },
    },
    async mounted() {
        this.initOptions()
    },
}
</script>
